// @file utils for full screen
/* eslint-disable dot-notation */

export const isFullscreenAvailable: boolean =
  document.fullscreenEnabled ||
  document['mozFullScreenEnabled'] ||
  document['webkitFullscreenEnabled'] ||
  document['msFullscreenEnabled']

function fullscreenElement(): Element {
  return (
    document.fullscreenElement ||
    document['mozFullScreenElement'] ||
    document['webkitFullscreenElement'] ||
    document['msFullscreenElement']
  )
}

function getRequestFullscreenMethod(el: Element): () => Promise<void> | void {
  const requestFullScreen =
    el.requestFullscreen || el['webkitRequestFullscreen'] || el['mozRequestFullScreen'] || el['msRequestFullscreen']
  return requestFullScreen && requestFullScreen.bind(el)
}

function getExitFullscreenMethod(): () => Promise<void> | void {
  const exitFullScreen =
    document.exitFullscreen ||
    document['webkitExitFullscreen'] ||
    document['mozCancelFullScreen'] ||
    document['msExitFullscreen']
  return exitFullScreen && exitFullScreen.bind(document)
}

export function isFullscreen(): boolean {
  return !!fullscreenElement()
}

export function enterFullscreen(): void {
  if (isFullscreen()) return
  const requestFullScreen = getRequestFullscreenMethod(document.documentElement)
  requestFullScreen && requestFullScreen()
}

export function exitFullscreen(): void {
  if (!isFullscreen()) return
  const exitFullScreen = getExitFullscreenMethod()
  exitFullScreen && exitFullScreen()
}

export function toggleFullscreen(): void {
  if (isFullscreen()) {
    exitFullscreen()
  } else {
    enterFullscreen()
  }
}

type observeFullscreenCallback = (isFullscreen: boolean) => void
export function observeFullscreen(callback: observeFullscreenCallback): (() => void) | null {
  if (!callback) return null
  const handler: EventListener = (): void => callback(isFullscreen())
  document.addEventListener('fullscreenchange', handler)
  document.addEventListener('webkitfullscreenchange', handler)
  document.addEventListener('mozfullscreenchange', handler)
  document.addEventListener('MSFullscreenChange', handler)
  return (): void => {
    document.removeEventListener('fullscreenchange', handler)
    document.removeEventListener('webkitfullscreenchange', handler)
    document.removeEventListener('mozfullscreenchange', handler)
    document.removeEventListener('MSFullscreenChange', handler)
  }
}
