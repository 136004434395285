// @file Window size store
import { browserCan } from '@@/bits/browser'
import device from '@@/bits/device'
import { observeFullscreen } from '@@/bits/fullscreen'
import window from '@@/bits/global'
import type { EdgeInsets } from '@@/bits/window'
import { observeVisualViewportSize, observeWindowSize } from '@@/bits/window'
import { defineStore } from 'pinia'
import { computed, ref } from 'vue'

// @see https://whimsical.com/sizes-AE8Cze2sDvD2eE56Q7eUo3
enum BreakPoints {
  PhoneSmall = 0,
  Phone = 360,
  TabletPortrait = 744,
  TabletLandscape = 1024,
  Desktop = 1440,
  DesktopBig = 1921,
  Desktop2XL = 2560,
  Desktop3XL = 3200,
}

export const useWindowSizeStore = defineStore('windowSize', () => {
  const windowWidth = ref(window.innerWidth ?? 1366)
  const windowHeight = ref(window.innerHeight ?? 768)
  const visualViewportWidth = ref(window.innerWidth ?? 1366)
  const visualViewportHeight = ref(window.innerHeight ?? 768)
  const visualViewportScale = ref(1)
  const safeAreaInsets = ref<EdgeInsets>({
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  })

  const resizeWindow = ({ width, height }): void => {
    windowWidth.value = width
    windowHeight.value = height
  }

  const resizeVisualViewport = ({ width, height, scale }): void => {
    visualViewportWidth.value = width
    visualViewportHeight.value = height
    visualViewportScale.value = scale
  }

  const unobserveWindowSize = observeWindowSize(resizeWindow)

  let unobserveVisualViewportChange
  if (device.touchable || browserCan('visualViewport')) {
    unobserveVisualViewportChange = observeVisualViewportSize(() => {
      resizeVisualViewport(window.visualViewport)

      // On some mobile browsers, e.g. Google Chrome on iOS, when the window resize event is fired on device orientation change,
      // the new width / height is not available in the resize event yet
      // Reference: - https://stackoverflow.com/questions/65648544/clientwidth-and-innerwidth-not-updating-on-ios-chrome-after-orientationchange
      //            - https://stackoverflow.com/questions/12452349/mobile-viewport-height-after-orientation-change
      // We should also check for visual viewport change, this would ensure the window has been resized
      resizeWindow({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    })
  }

  // BREAKPOINTS
  // If you are looking for something like `isSmallerThanOrEqualTabletLandscape`, negate `isBiggerThanTabletLandscape` instead.
  const biggerThanBreakpoints = {
    isBiggerThanPhone: computed(() => windowWidth.value > BreakPoints.Phone),
    isBiggerThanTabletPortrait: computed(() => windowWidth.value > BreakPoints.TabletPortrait),
    isBiggerThanTabletLandscape: computed(() => windowWidth.value > BreakPoints.TabletLandscape),
    isBiggerThanDesktop: computed(() => windowWidth.value > BreakPoints.Desktop),
    isBiggerThanDesktopBig: computed(() => windowWidth.value > BreakPoints.DesktopBig),
  }
  const smallerThanBreakpoints = {
    isSmallerThanTabletPortrait: computed(() => windowWidth.value < BreakPoints.TabletPortrait),
    isSmallerThanTabletLandscape: computed(() => windowWidth.value < BreakPoints.TabletLandscape),
    isSmallerThanDesktop: computed(() => windowWidth.value < BreakPoints.Desktop),
    isSmallerThanDesktopBig: computed(() => windowWidth.value < BreakPoints.DesktopBig),
    isSmallerThanDesktop2XL: computed(() => windowWidth.value < BreakPoints.Desktop2XL),
    isSmallerThanDesktop3XL: computed(() => windowWidth.value < BreakPoints.Desktop3XL),
  }

  const isMobile = device.mobile

  // OLD BREAKPOINTS, to be deprecated eventually
  const isPhone = computed(() => windowWidth.value < 768)
  const isSmallPhone = computed(() => windowWidth.value <= 375)

  // FULLSCREEN
  const isFullscreen = ref(false)

  const unobserveFullscreen = observeFullscreen((isFullscreenObserved) => (isFullscreen.value = isFullscreenObserved))

  const enterFullscreen = (): void => {
    isFullscreen.value = true
  }

  const exitFullscreen = (): void => {
    isFullscreen.value = false
  }

  return {
    windowWidth,
    windowHeight,
    visualViewportWidth,
    visualViewportHeight,
    visualViewportScale,
    safeAreaInsets,
    resizeWindow,
    resizeVisualViewport,
    unobserveWindowSize,
    unobserveVisualViewportChange,
    ...biggerThanBreakpoints,
    ...smallerThanBreakpoints,
    isMobile,

    // OLD BREAKPOINTS
    isPhone,
    isSmallPhone,

    // FULLSCREEN
    isFullscreen,
    unobserveFullscreen,
    enterFullscreen,
    exitFullscreen,
  }
})

export { BreakPoints }
